/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('restrictions', {
    parent: 'root',
    url: '/restrictions',
    templateUrl:
      'apps/restrictions-readonly/templates/acp-restrictions-readonly.ng.html',
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.apps.restrictions-readonly" */ 'apps/restrictions-readonly').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
